import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import WhyCarPlannerData from './data/WhyCarplannerData'

const WhyCarPlannerList = dynamic(() => import('../components/WhyCarPlanner'))

interface Props {
  title: string
}

const WhyCarPlannerContainer: FC<Props> = ({ title }) => (
  <>
    <WhyCarPlannerList title={title} items={WhyCarPlannerData} />
  </>
)

export default WhyCarPlannerContainer
