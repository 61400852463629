import { GreenCarIcon, GreenClickIcon, GreenSupportIcon } from 'src/icons'

const WhyCarPlannerData = [
  {
    title: 'Cerca l’auto perfetta\nper le tue esigenze!',
    description:
      'Selezioniamo per te le migliori offerte sul mercato per il Noleggio a Lungo Termine e l’Acquisto!',
    icon: <GreenCarIcon />,
  },
  {
    title: 'Acquista o Noleggia\n online in pochi passi',
    description:
      'Ti guidiamo nella scelta della tua nuova auto con un servizio semplice, veloce e trasparente, ottimizzando i tempi!',
    icon: <GreenClickIcon />,
  },
  {
    title: 'Consulenti esperti sempre\n a tua disposizione',
    description:
      'Il cliente sempre al centro: puoi contare sulla nostra esperienza per rispondere a tutte le tue domande e consigliarti al meglio! ',
    icon: <GreenSupportIcon />,
  },
]

export default WhyCarPlannerData
